import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionItem,
  Button,
  ButtonSet,
  Column,
  DatePicker,
  DatePickerInput,
  Form,
  Grid,
  ToastNotification,
} from '@carbon/react';
import { useTranslation } from 'react-i18next';
import '../FormMultisteps.scss';
import './FormStep2.scss';
import { SurveySupplierInput } from '../../../types/SurveySupplierInput';
import VideoEmbed from '../../Vimeo/Vimeo';
import { Item, MonthYearPicker } from '../../Datepicker/month-year-picker'; // Import VideoEmbed component

const EN_GB_LOCALES = 'en-GB';

interface FormStep2State {
  start: Date;
  end: Date;
  faq: string;
}

type Props = {
  formState: SurveySupplierInput;
  handleChange: (event: { target: { name: any; value: any }; name?: any; value?: any }) => void;
  nextStep: () => void;
  prevStep: () => void;
};

const parseLocaleDateString = (date: string) => {
  const [day, month, year] = date.split('/');
  return new Date(`${year}-${month}-${day}`);
};

const FormStep2 = ({ formState, handleChange, nextStep, prevStep }: Props) => {
  const { t } = useTranslation();
  const [videoKey, setVideoKey] = useState(0);
  const [showToast, setShowToast] = useState(false);

  useEffect(() => {
    fetch('api/surveys/periods')
      .then((response) => response.json())
      .then((data) => {
        // Adjust this line to match the structure of your actual JSON data
        setItems(data.period_list);
      })
      .catch((error) => console.error('Error fetching SPDX data:', error));
  }, []);

  const [items, setItems] = useState<Item[]>([]);

  useEffect(() => {
    if (items.length > 0) {
      const startDate =
        items.find((item) => item.id === formState.startPeriod) ||
        items.find((item) => item.month === 1 && item.year === 2023);
      const endDate =
        items.find((item) => item.id === formState.endPeriod) ||
        items.find((item) => item.month === 12 && item.year === 2023);

      setSelectedStartItem(startDate);
      setSelectedEndItem(endDate);
    }
  }, [items]);

  const [selectedStartItem, setSelectedStartItem] = useState<Item>();
  const [selectedEndItem, setSelectedEndItem] = useState<Item>();

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    if (!selectedStartItem || !selectedEndItem) {
      alert('Both start and end dates must be selected.');
      return;
    }

    nextStep();
  };

  useEffect(() => {
    if (selectedStartItem) {
      handleChange({
        target: {
          name: 'startPeriod',
          value: selectedStartItem.id,
        },
      });
    }
    if (selectedEndItem) {
      handleChange({
        target: {
          name: 'endPeriod',
          value: selectedEndItem.id,
        },
      });
    }
  }, [selectedStartItem, selectedEndItem]);

  const handlePeriodChange = (event: { selectedItem: Item }) => {
    setSelectedStartItem(event.selectedItem);

    if (event.selectedItem) {
      const startDate = new Date(
        Date.UTC(event.selectedItem.year, event.selectedItem.month - 1, 1),
      );

      const newEnd = new Date(startDate);
      newEnd.setFullYear(startDate.getFullYear() + 1);
      newEnd.setDate(newEnd.getDate() - 1);

      setSelectedEndItem(
        items.find(
          (item) => item.month === newEnd.getMonth() + 1 && item.year === newEnd.getFullYear(),
        ) || items[0],
      );
    }
  };

  return (
    <Form onSubmit={handleSubmit} id="step2" className="form-step">
      <div className="grid-container-element">
        <div className="grid-child-element">
          <Grid>
            <Column lg={16} md={8} sm={4}>
              <h4>{t('survey.step2.name')}</h4>
              {t('survey.step2.description')}
            </Column>
            <Column lg={3} md={4} sm={16}>
              <MonthYearPicker
                title={'Start Period'}
                items={items.filter((item) => item.year <= new Date().getFullYear() - 1)}
                value={selectedStartItem}
                handleDateChange={handlePeriodChange}
              />

              {showToast && (
                <ToastNotification
                  role="status"
                  title={t('survey.step2.toast.title')}
                  subtitle={t('survey.step2.toast.subtitle')}
                  onClose={() => setShowToast(false)}
                />
              )}
            </Column>
            <Column lg={3} md={4} sm={16}>
              <MonthYearPicker
                items={items}
                value={selectedEndItem}
                title={'End Period'}
                disabled={true}
              />
            </Column>
          </Grid>
        </div>
        <div className="grid-child-element">
          <Grid>
            <Column lg={16} md={8} sm={4}>
              <h5 className="faq">{t('survey.step2.faq.title')}</h5>
              <Accordion>
                <AccordionItem title={t('survey.step2.faq.accordion1.title')}>
                  {t('survey.step2.faq.accordion1.text')}
                  <div>
                    <VideoEmbed
                      src="https://video.ibm.com/embed/recorded/133972395"
                      title="CBAM Academy - Reporting Periods for Operators"
                      width="100%"
                      height="auto"
                      key={videoKey.toString()} // Convert videoKey to string
                    />
                  </div>
                </AccordionItem>
                <AccordionItem title={t('survey.step2.faq.accordion2.title')}>
                  {t('survey.step2.faq.accordion2.text')}
                </AccordionItem>
                <AccordionItem title={t('survey.step2.faq.accordion3.title')}>
                  {t('survey.step2.faq.accordion3.text')}
                </AccordionItem>
              </Accordion>
            </Column>
          </Grid>
        </div>
      </div>
      <Grid>
        <Column lg={16} md={8} sm={4}>
          <div className="step-btn-set-container">
            <ButtonSet className="step-btn-set">
              <Button kind="secondary" tabIndex={0} onClick={prevStep}>
                {t('common.buttons.back')}
              </Button>
              <Button kind="primary" tabIndex={0} type="submit">
                {t('common.buttons.next')}
              </Button>
            </ButtonSet>
          </div>
        </Column>
      </Grid>
    </Form>
  );
};

export default FormStep2;
