import { Column, Dropdown, Grid, FormLabel } from '@carbon/react';

export type Item = { id: number; month: number; year: number };

type Props = {
  title: string;
  items: Item[];
  value?: Item;
  handleDateChange?: (event: { selectedItem: Item }) => void;
  disabled?: boolean;
};

export const MonthYearPicker = ({ title, items, value, handleDateChange, disabled }: Props) => {
  return (
    <Dropdown
      id="year-picker"
      titleText={title}
      label="Select a Period"
      items={items}
      itemToString={(item: any) =>
        item ? `${String(item.month).padStart(2, '0')}-${item.year}` : ''
      }
      onChange={handleDateChange}
      initialSelectedItem={items[0]}
      selectedItem={value}
      disabled={disabled}
    />
  );
};
